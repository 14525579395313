<template>
  <div>
    <v-data-table :headers="headers" :items="skus" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>SKUs</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2 mr-1"
            @click="() => showEditSkuModal(null)"
            :disabled="isEditable"
          >
            New Item
          </button>
          <button
            type="button"
            class="btn btn-info px-4 py-2 ls1 mb-2"
            :disabled="isEditable"
            @click="showImportModal()"
          >
            Import
          </button>
        </v-toolbar>
      </template>

      <template v-slot:item.label_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.transfer_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.bundle_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.qc_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.assemble_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.special_pack_flag="{ value }">
        {{ value === true ? "Yes" : "No" }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="() => showEditSkuModal(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="() => removeSkus(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <EditSku
      ref="editSku"
      :actionFunction="editSkus"
      :skuItem="skuItem"
      :carriers="carriers"
      :clientId="clientId"
      :receiving_type="receiving_type"
    ></EditSku>
    <ImportData
      ref="importData"
      label="SKU"
      :setData="setImportDataToDataTable"
      :customer="customer"
    >
    </ImportData>
  </div>
</template>

<script>
import EditSku from "@/own/components/fulfillment/asn/skuContainer/EditSku";
import ImportData from "@/own/components/fulfillment/asn/skuContainer/ImportData";
import Swal from "sweetalert2";
export default {
  name: "SkuDatatable",
  props: [
    "setSku",
    "skus",
    "skuItem",
    "carriers",
    "clientId",
    "customer",
    "isEditable",
    "receiving_type",
  ],
  components: { EditSku, ImportData },
  data: () => ({}),
  computed: {
    headers() {
      let headers = [
        { text: "SKU ID", value: "sku_id" },
        { text: "SKU Barcode", value: "sku_barcode" },
        { text: "Batch Number", value: "batch_number" },
        // { text: "SKU Carton Number", value: "sku_carton_number" },
        { text: "Expected Quantity", value: "expected_qty" },
        { text: "Require Labeling", value: "label_flag" },
        { text: "Transfer", value: "transfer_flag" },
        { text: "Require Bundling", value: "bundle_flag" },
        { text: "Require QC", value: "qc_flag" },
        { text: "Require Assembling", value: "assemble_flag" },
        { text: "Expire Date", value: "exp_date" },
      ];
      if (this.receiving_type == "2" || this.receiving_type == "3") {
        headers.splice(3, 0, {
          text: "Carton number",
          value: "carton_number",
        });
      } else {
        headers.splice(3, 0, {
          text: "SKU Carton Number",
          value: "carton_number",
        });
      }
      if (!this.isEditable) {
        headers.push({ text: "Actions", value: "actions", sortable: false });
      }
      return headers;
    },
  },
  methods: {
    showImportModal() {
      this.$refs.importData.toggleModal();
    },
    setImportDataToDataTable(importedData) {
      this.setSku([]);
      this.setSku(importedData);
    },
    showEditSkuModal(item) {
      if (this.isEditable) {
        return;
      }
      if (!this.customer && !this.clientId) {
        Swal.fire({
          title: "",
          text: "Please, select customer for adding SKUs!",
          icon: "warning",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
        return;
      }
      this.$refs.editSku.toggleModal(item);
    },

    editSkus(item, editItem) {
      if (editItem) {
        this.removeSkus(editItem);
      }
      this.$nextTick(() => {
        let new_skus = [...this.skus];
        let copy = new_skus.findIndex(
          (sku) =>
            sku.sku_id == item.sku_id &&
            sku.sku_barcode == item.sku_barcode &&
            sku.batch_number === item.batch_number &&
            sku.carton_number === item.carton_number
        );
        if (copy > -1) {
          new_skus[copy].expected_qty =
            parseInt(new_skus[copy].expected_qty) + parseInt(item.expected_qty);
        } else {
          new_skus.push(item);
        }
        this.setSku(new_skus);
      });
    },
    removeSkus(item) {
      if (this.isEditable) {
        return;
      }
      let new_skus = [...this.skus];
      const index = new_skus.findIndex(
        (sku) =>
          sku.sku_id === item.sku_id && sku.sku_barcode === item.sku_barcode
      );

      new_skus.splice(index, 1);
      this.setSku(new_skus);
    },
  },
};
</script>
