<template>
  <v-menu
    content-class="m-0 p-0"
    transition="scale-transition"
    offset-y
    bottom
    nudge-bottom="5"
    :left="!$vuetify.rtl"
    :right="$vuetify.rtl"
    rounded
    max-height="75%"
  >
    <template v-slot:activator="{ on, attrs }">
      <button
        v-on="on"
        v-bind="attrs"
        class="poppins btn btn--export-filter px-5"
      >
        <span class="svg-icon">
          <v-icon size="18">mdi-printer</v-icon>
        </span>
        <span>Print</span>
        <span class="svg-icon mr-0 ml-2">
          <v-icon size="18">mdi-chevron-down</v-icon>
        </span>
      </button>
    </template>
    <div class="py-1 w-175px bg-white">
      <div
        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
        v-for="(print, index) in types"
        :key="index"
        @click="() => onPrint(print.value, print.name)"
      >
        <div
          class="py-2 w-100 text-left poppins font-size-sm"
          :class="[
            { 'mt-2': index === 0 },
            { 'mb-2': index === types.length - 1 },
          ]"
        >
          {{ print.name }}
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  SET_REPORT_MODAL_STATE,
  SET_REPORT_MODAL_DATA,
} from "@/core/services/store/config.module";
import Swal from "sweetalert2";

export default {
  name: "Print",
  props: {
    /** @type {Array<{name:string, value: string}>} */
    types: {
      required: true,
      type: Array,
    },
    /** @type {Array<{id: string}>} */
    items: {
      required: true,
      type: Array,
    },
  },
  methods: {
    showReport(type, items) {
      let total = items.length;
      let available = items.filter(
        (item) => item.extra_data.detail.print[`${type}`] === true
      );
      let notAvailable = items.filter(
        (item) => item.extra_data.detail.print[`${type}`] === false
      );
      let typeAmountAvailable = available.length;
      if (typeAmountAvailable !== total) {
        let message = notAvailable.map((item) => `<li>${item.id}</li>`);

        Swal.fire({
          title: "Warning",
          html: `<div>
                    <div>The ${typeAmountAvailable} of ${total} available for ${type}</div>
                    <div>
                      <span>ASNs that are not printed:</span>
                      <ul class="text-left pl-6">${message.join("")}</ul>
                    </div>
                  </div>`,
          icon: "warning",
          showConfirmButton: true,
        });
      }
      return {
        total: total,
        availableAmount: typeAmountAvailable,
        available: available,
        notAvailable: notAvailable,
      };
    },
    onPrint(val, name) {
      if (this.items.length > 0) {
        let isRepotShown = this.showReport(val, this.items);
        if (isRepotShown.availableAmount > 0) {
          this.$store.commit(SET_PAGE_LOADING, true);
          let itemSystemCodes = this.items.map((item) => item.id);
          let data = { type: val, asns: itemSystemCodes };
          ApiService.post("/asns/print", data)
            .then((response) => {
              this.$store.commit(SET_REPORT_MODAL_DATA, {
                total_asns_count: response.data.total_asns_count,
                successful_asns_count: response.data.successful_asns_count,
                failed_asns_count: response.data.failed_asns_count,
                successful_asns: response.data.successful_asns,
                failed_asns: response.data.failed_asns,
                message: response.data.message,
              });
              this.$store.commit(SET_REPORT_MODAL_STATE, true);
              if (response.data.successful_asns_count > 0) {
                if (response.data.path) {
                  response.data.path.forEach((element) => {
                    const link = document.createElement("a");
                    link.href = element;
                    link.target = "_blank";
                    link.download = "asn.csv";
                    link.click();
                  });
                }
              }
            })
            .finally(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            });
        } else {
          Swal.fire({
            title: "Warning",
            text: `${name} is not ready for selected ASNs.`,
            icon: "warning",
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one ASN!",
          icon: "warning",
          showConfirmButton: false,
        });
      }
    },
  },
};
</script>

<style>
.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
